import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, ActionIcon, Flex, Space } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';

export default function PendingAlert({ count: { fbs, ppbs } }) {
	const navigate = useNavigate();

	function handleFbsClick() {
		navigate('/pending/fbs');
	}

	function handlePpbsClick() {
		navigate('/pending/ppbs');
	}

	function RenderCard({ count, testName, handleClick }) {
		const backgroundColor = testName === 'FBS' ? '#ffe9f2' : '#fff0cc';
		const color = testName === 'FBS' ? '#d6084c' : '#ac7700';
		return (
			<Card shadow="md" padding="lg" radius="md" withBorder
				style={{ backgroundColor: backgroundColor, color: color, width: '100%' }}
				onClick={handleClick}>
	  		<Flex align="center" justify-content="space-between" style={{ width: "100%" }}>
	  			<p style={{ flex: '1 1 auto' }}>
	  				<span style={{ fontWeight: 'bold' }}> You have {count} pending {testName} { count === 1 ? "re-test" : "re-tests" }.</span><br/>
	  				Click here to fill.
	  			</p>
	  			<ActionIcon variant="transparent" size="xl" color="#d6084c" style={{ flex: '0 0 auto' }}>
	  				<IconChevronRight />
	  			</ActionIcon>
	  		</Flex>
			</Card>
		);
	}

	if (fbs <= 0 && ppbs <=0 ) {
		return (
			<></>
		);
	}

  return (
		<div>
  		{ 
  			fbs ? 
  				<RenderCard count={fbs} testName={"FBS"} handleClick={handleFbsClick} />
  				: null
  		}
  		
  		<Space h="md" />

  		{
  			ppbs ?
  				<RenderCard count={ppbs} testName={"PPBS"} handleClick={handlePpbsClick} />
  				: null
  		}
  	</div>
  )
}