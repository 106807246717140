import * as React from 'react';
import { Grid, Flex, Center, Loader, Text, Title, Card } from '@mantine/core';

import styles from './index.module.css';

export default function Dashboard() {
	const [ data, setData ] = React.useState(null);

	function getData() {
		fetch(process.env.REACT_APP_API_URL_BASE + '/api/getDashboardData/', {
		  method: 'GET',
		  credentials: 'include',
		  headers: {'Content-Type': 'application/json'}
		}).then((response, error) => {
		  if (response.status !== 200) {
		    return;
		  }

		  response.json().then((responseBody, error) => {
		  	setData(responseBody);
		  	console.log(responseBody);
		  })
		}).catch((error) => {
			console.log(error);
		});
	}

	// This is done to call useEffect only once, when the Component is mounted.
	const hasMounted = React.useRef(false);
	React.useEffect(() => {
	  if (hasMounted.current) { return; } 
	  console.log('DATA: ', data);

		getData();
	  hasMounted.current = true;
	}, []);

	function TestCount() {
		return (
			<Card shadow="md" padding="lg" radius="md" withBorder style={{ width: '100%' }}>
				<Grid gutter={2}>
					<Grid.Col span={6} className={styles.totalSurveyed} >
						<Flex align="center" justify-content="center" direction="column">
							<Text 
								component="span" size="lg" 
								styles={{ margin: "0px", color: "red" }}
								c="blue">
								Total People Surveyed
							</Text>
							<Title order={1}>
								<Text span variant="gradient" gradient={{ from: 'blue', to: 'cyan', deg: 90 }} inherit>
									{ data.totalSurveyed }
								</Text>
							</Title>
						</Flex>
					</Grid.Col>

					<Grid.Col span={6} className={styles.diabeticsIdentified}>
						<Flex align="center" justify-content="center" direction="column">
							<Text component="span" size="lg" c="red">
								Diabetics Identified
							</Text>
							<Title order={1}>
								<Text span variant="gradient" gradient={{ from: 'red', to: 'pink', deg: 90 }} inherit>
									{ data.diabeticsIdentified }
								</Text>
							</Title >
						</Flex>
					</Grid.Col>
				</Grid>
			</Card>
		);
	}

	function TabularData() {
		return (
			<div>
			</div>
		);
	}

	return (
		<div>
			{
				! data ?
				(<Center style={{ height: '100%' }}><Loader color="cyan" type="bars" /></Center>) :
				(
					<>
						<TestCount />

						<TabularData />
					</>
				)
			}
		</div>
	);
}