import * as React from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Button, TextInput, Text, Flex, ActionIcon, 
	Divider, Box, Modal, Space, NumberInput, Alert } from '@mantine/core';
import { useForm, useField } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import { IconEdit, IconSearch } from '@tabler/icons-react';
import { filterData, SearchType } from 'filter-data';
import { IconInfoCircle, IconChecks, IconPencil } from '@tabler/icons-react';

import { AppContext } from '../components/AppContext';

export default function Pending() {
	const navigate = useNavigate();

	const pendingList = React.useRef({
		count: null,
		data: null
	});

	const { test } = useParams();

	// Takes the pending count from AppContainer.
	const [ pendingCounts, setPendingCounts ] = useOutletContext();

	// If the alert for high reading is visible.
	const [ alertVisible, alertHandlers ] = useDisclosure(false);

	// If the modal for data entry is visible.
	const [ modalVisible, modalHandlers ] = useDisclosure(false);

	// To disable/enable the buttons/inputs while submitting the form.
	const [ formState, setFormState ] = React.useState('reset');

	const { globalState, setGlobalState } = React.useContext(AppContext);

	// The list that is rendered.
	const [ renderedList, setRenderedList ] = React.useState([]);

	// The reading that is selected for updation.
	const [ selectedReading, setSelectedReading ] = React.useState({});

	// Used to edit Aadhaar Number.
	const [ aadhaarEditable, setAadhaarEditable ] = React.useState(false);

	// To set error messages received from the server on submitting the form.
	const [ errorMessage, setErrorMessage ] = React.useState(null);

	const form = useForm({
	  mode: 'uncontrolled',
	  initialValues: {
	    reading2: ''
	  },

	  validate: {
	    reading2: (value) => (/^[\d.]+$/.test(value) 
	    	? (value < 60 ? 'Reading cannot be less than 60' : null) 
	    	: (value ? 'Invalid reading' : 'Please enter reading')),
	    aadhaar: (value) => (/^\d{12}$/.test(value) 
	    	? null 
	    	: (value ? 'Invalid Aadhaar number' : 'Please enter Aadhaar number')) 
	  }
	});

	const searchInput = useField({
		initialValue: '',
		onValueChange: handleSearchInputChange
	});

	/**
	 * Handles when the form is submitted.
	 * 
	 * @param  {JSON} values The values of the inputs in the form.
	 * @param  {Object}	event The event object.
	 * 
	 * @return {void}
	 */
	function handleSubmit(values, event) {
		event.preventDefault();

		setFormState('submitting');

		fetch(process.env.REACT_APP_API_URL_BASE + '/api/updateReading/' + test, {
		  method: 'POST',
		  credentials: 'include',
		  headers: {'Content-Type': 'application/json'},
		  body: JSON.stringify({
		    id: selectedReading.id,
		    [test]: parseFloat(values.reading2),
		    aadhaar: values.aadhaar,	// Aadhaar can be updated because some NULL values have been entered.
		    referral: alertVisible
		  })
		}).then((response, error) => {
		  if (response.status !== 200) {
		  	response.json().then((responseBody, error) => {
		  		setErrorMessage(responseBody ? responseBody.error : null);
          setFormState('error');
		  	});

		  	return;
		  }

		  setFormState('submitted');

		  pendingList.count -= 1;

		  // Set the property 'done' as true, so that it doesn't get rendered.
		  pendingList.data[selectedReading.index].done = true;
		  
		  // pendingList.data.splice(selectedReading.index, 1);
		  setPendingCounts({
		  	...pendingCounts,
		  	[test]: pendingList.count
		  });

		  if (!searchInput.getValue()) {
		  	// If search input is empty, re-render the list.
		  	handleSearchInputChange();
		  } else {
		  	// If search input is not empty, set it empty. The list will automatically re-render when
		  	// the handleSearchInputChange function is called as a consequence.
		  	searchInput.setValue('');	
		  }
		}).catch((error) => {
		  setFormState('error');
		});
	}

	/**
	 * Get the list of pending readings from the server.
	 * 
	 * @return {void}
	 */
	function getPendingList() {
		fetch(process.env.REACT_APP_API_URL_BASE + '/api/getPendingList/' + test, {
      method: 'GET',
      credentials: 'include'
    }).then((response, error) => {
      if (response.status === 401) {
        navigate('/login');
        return;
      } else if (response.status !== 200) {
        console.log('SOMETHING WENT WRONG: ', response.status);
        return;
      } else {
        response.json().then((responseBody, error) => {
          if ( !globalState.name ) {
            setGlobalState({
              ...globalState,
              name: responseBody.user.name,
              designation: responseBody.user.designation,
              userid: responseBody.user.userid
            });
          }

          pendingList.count = responseBody.count;
          pendingList.data = responseBody.data;

          handleSearchInputChange();
        })
      }
    }).catch((error) => {
      console.log('SOMETHING WENT WRONG');
      console.log(error);
    });
	}

	/**
	 * Searches the pendingList for entries matching the searchString.
	 * 
	 * @param  {String} searchString The input entered in the search box.
	 * @param  {Integer} limit The maximum number of array elements that need to be rendered.
	 * 
	 * @return {Array} An array of the matched entries.
	 */
  function searchList(searchString, limit=10) {
  	
  	// If the searchString is empty, render the whole list.
  	if (! searchString) {
  		return pendingList.data.slice(0, limit);
  	}

  	var searchConditions = null;
  	if (isNaN(searchString)) {
  		// If the search string is not a valid number, search the Name.
  		searchConditions = [{
  			key: 'name',
  			value: searchString,
  			type: SearchType.LK
  		}];
  	} else {
  		// If the search string is a valid number, search Aadhaar and Phone number.
  		searchConditions = [{
  			key: ['aadhaar', 'mobile'],
  			value: searchString,
  			type: SearchType.LK
  		}];
  	}

  	return filterData(pendingList.data, searchConditions, { caseSensitive: false, limit: 10 });
  }

  /**
   * Takes the list of matched entries and returns an element that will render them.
   * 
   * @param  {Array} list An array of matching entries that need to be rendered.
   * 
   * @return {Element} The rendered elements.
   */
  function renderList(list) {
  	return list.map((item) => {
  		if (item.done) return (<></>);

  		return (
  			<div key={item.id} data-index={item.index} onClick={handleResultClick}>
		  		<Flex align="center" justify-content="space-between" style={{ width: "100%" }}>
		  			<div style={{ flex: '1 1 auto' }}>
		  				<Text fw={700} style={{ marginBottom: 0, marginTop: 0 }} c="blue" >{item.name}</Text>
		  				<Text style={{ marginTop: 0, marginBottom: 0 }} size="sm" >
		  					Aadhaar: {
		  						item.aadhaar 
		  						? item.aadhaar.slice(0, 4) + '-' + item.aadhaar.slice(4, 8) + '-' + item.aadhaar.slice(8, 12)
		  						: 'NA'} 
		  					<br/> 
		  					Phone: {item.mobile}
		  				</Text>
		  			</div>
		  			<ActionIcon variant="transparent" size="xl" style={{ flex: '0 0 auto' }}>
		  				<IconEdit />
		  			</ActionIcon>
		  		</Flex>
		  		<Divider my="md" style={{ marginTop: '0px !important' }} />
		  	</div>
	  	);
  	});
  } 

  /**
   * Called when the value of the search input changes.
   * 
   * @param  {String} value The value of the search input.
   * 
   * @return {void}
   */
  function handleSearchInputChange(value) {
  	const results = searchList(value);
  	setRenderedList(renderList(results));
  }

  /**
   * Handles when one of the search result is clicked.
   * 
   * @param  {Object} event The event object.
   * 
   * @return {void}
   */
  function handleResultClick(event) {
  	const index = parseInt(event.currentTarget.getAttribute('data-index'));
  	setSelectedReading(pendingList.data[index]);

  	form.reset();
  	setFormState('reset');

  	// Setting aadhaar value in the modal.
  	form.setFieldValue('aadhaar', pendingList.data[index].aadhaar);
  	setAadhaarEditable(false);
  	modalHandlers.open();
  }

  // This is done to call useEffect only once, when the Component is mounted.
  const hasMounted = React.useRef(false);
  React.useEffect(() => {
    if (hasMounted.current) { return; } 

  	getPendingList();
    hasMounted.current = true;
  }, []);

  /**
   * Called when the reading input is changed for the selected reading item.
   * 
   * @param  {Number} value The value of the reading2 field.
   * 
   * @return {value}
   */
  function readingChanged(value) {
    if (value >= globalState.constants.THRESHOLDS[test]) {
      alertHandlers.open();
    } else {
      alertHandlers.close();
    }
  }

	return (
		<div>
			{
				(
					<Text>
						<Text span fw={700}>{ pendingCounts && pendingCounts[test] } </Text> 
						pending { test === 'fbs' ? 'FBS ' : 'PPBS ' } 
						{ pendingCounts[test] === 1 ? 'reading' : 'readings' }.
					</Text> 
				)
			}

			<Space h="md" />

			<TextInput {...searchInput.getInputProps()} placeholder="Search with Name/Aadhaar/Mobile" size="lg"/>

			<Divider
        my="xs"
        variant="dashed"
        labelPosition="center"
        label={
          <>
            <IconSearch size={12} />
            <Box ml={5}>Search results</Box>
          </>
        }
      />

			<div>
				{ renderedList }
			</div>

			<Modal 
			  opened={modalVisible} 
			  onClose={modalHandlers.close} 
			  centered
			  title="Enter second reading"
			  overlayProps={{
			    backgroundOpacity: 0.5,
			    blur: 3
			  }}
			  withCloseButton={false}
			>
			  <form onSubmit={form.onSubmit(handleSubmit)}>
			  	<TextInput
			  	  withAsterisk
			  	  label="Name"
			  	  placeholder="Name"
			  	  size="lg"
			  	  value={selectedReading.name}
			  	  disabled={true}
			  	/>

			  	<Space h="md" />

			  	<NumberInput
			  	  withAsterisk
			  	  label="Aadhaar Number"
			  	  placeholder="Aadhaar Number"
			  	  size="lg"
			  	  key={form.key('aadhaar')}
            {...form.getInputProps('aadhaar')}
			  	  inputMode="numeric"
			  	  allowDecimal="false"
			  	  allowNegative="true"
			  	  disabled={
			  	  	(formState === 'submitting' || formState === 'submitted') 
			  	  	? true 
			  	  	: (!aadhaarEditable && selectedReading.aadhaar)}
			  	  rightSection={
			  	  	selectedReading.aadhaar && !aadhaarEditable
			  	  	? <ActionIcon variant="subtle" onClick={() => setAadhaarEditable(true)}><IconPencil /></ActionIcon>
			  	  	: null
			  	  }
			  	  rightSectionPointerEvents="auto"
			  	/>

			  	<Space h="md" />

			  	<NumberInput
			  	  withAsterisk
			  	  label="RBS"
			  	  placeholder="RBS"
			  	  hideControls="true"
			  	  size="lg"
			  	  inputMode="numeric"
			  	  allowDecimal="true"
			  	  allowNegative="false"
			  	  value={selectedReading.rbs}
			  	  disabled={true}
			  	/>

			  	{
			  		(test === 'fbs' ? (
			  			selectedReading.ppbs ? (
			  				<NumberInput
			  				  withAsterisk
			  				  label="PPBS"
			  				  placeholder="PPBS"
			  				  hideControls="true"
			  				  size="lg"
			  				  inputMode="numeric"
			  				  allowDecimal="true"
			  				  allowNegative="false"
			  				  value={selectedReading.ppbs}
			  				  disabled={true}
			  				/>
			  			) : null
			  		) : (
			  			selectedReading.fbs ? (
			  				<NumberInput
			  				  withAsterisk
			  				  label="FBS"
			  				  placeholder="FBS"
			  				  hideControls="true"
			  				  size="lg"
			  				  inputMode="numeric"
			  				  allowDecimal="true"
			  				  allowNegative="false"
			  				  value={selectedReading.fbs}
			  				  disabled={true}
			  				/>
			  			) : null
			  		))
			  	}

			  	<Space h="md" />

			  	<NumberInput
            withAsterisk
            label={test === 'fbs' ? 'FBS' : 'PPBS'}
            placeholder={test === 'fbs' ? 'FBS' : 'PPBS'}
            key={form.key('reading2')}
            {...form.getInputProps('reading2')}
            hideControls="true"
            size="lg"
            inputMode="numeric"
            allowDecimal="true"
            allowNegative="false"
            onChange={readingChanged}
            onBlur={(event) => { form.setFieldValue('reading2', event.target.value) }}
            disabled={formState === 'submitting' || formState === 'submitted'}
          />

          {
            alertVisible ? (
              <div>
                <Space h="md" />
                <Alert variant="light" color="red" title="High reading" icon={<IconInfoCircle />} >
                  Since the {test.toUpperCase()} reading is more 
                  than {globalState.constants.THRESHOLDS[test]}, <b>please refer the person to the nearest PHC</b>.
                </Alert>
              </div>
            ) : ""
          }

          {
          	formState === 'error' ? (
          		<>
          			<Space h="sm" />
          			<Text c="red">ERROR: { errorMessage ? errorMessage : "Something went wrong. Try again." }</Text>
          		</>
          	) : ""
          }
			  

				  <Space h="md" />

				  <Flex justify="flex-end">
				    <Button size="md"
				      onClick={() => {
				      	form.reset();
				        modalHandlers.close();
				      }}
				      variant="light"
				      style={{ marginRight: '15px' }}
				      disabled={formState === 'submitting'}
				      >
				        Dismiss
				    </Button>

				    <Button 
				      type="submit" size="md"
				      variant="gradient" gradient={{ from: 'green', to: 'cyan', deg: 90 }}
				      loading={formState === 'submitting'}
				      disabled={formState === 'submitted'}
				      leftSection={formState === 'submitted' ? (<IconChecks />) : null}
				    >
				      { formState === 'reset' || formState === 'error' ? 'Submit' : 'Submitted' }
				    </Button>
			  	</Flex>
			  </form>
			</Modal>
		</div>
	);
}