import * as React from 'react';
import { useOutletContext } from 'react-router-dom';
import { Text, Space } from '@mantine/core';

import { AppContext } from '../components/AppContext';
import NewEntry from '../components/NewEntry';
import PendingAlert from '../components/PendingAlert';

export default function Home() {
  const [ pendingCounts ] = useOutletContext();
  const { globalState } = React.useContext(AppContext);

  return (
    <div>
      <div>
        <Text>Welcome <b>{ globalState.name }</b></Text>
      </div>

      <Space h="md" />

      <PendingAlert count={pendingCounts} />
      <Space h="md" />
      <NewEntry />
    </div>
  );
}
