import * as React from 'react';
import { AppShell, Title, Button } from '@mantine/core';

import styles from './index.module.css';
import { useNavigate } from 'react-router-dom';

export default function Header() {
  const navigate = useNavigate();
  const [ loggingOut, setLoggingOut ] = React.useState(false);

  function logout() {
    setLoggingOut(true);
    localStorage.clear();

    fetch(process.env.REACT_APP_API_URL_BASE + '/api/logout', {
      method: 'GET',
      credentials: 'include'
    }).then((response, error) => {
      if (response.status !== 200) {
        console.log('SOMETHING WENT WRONG');
        setLoggingOut(false);
      } else {
        navigate('/login');
      }
    }).catch((error) => {
      console.log('SOMETHING WENT WRONG');
      console.log(error);
    })
  }

  function toHome() {
    navigate('/home');
  }

  return (
    <AppShell.Header padding="md" className={styles.header}>
      <div className="maxWidthContainer">
        <div className={styles.headerLogoContainer} onClick={toHome} >
          <img src="/Telangana-LOGO.webp" className={styles.headerLogo} alt="logo" />
        </div>
        <div className={styles.headerNameContainer} onClick={toHome} >
          <Title order={4} className={styles.heading}>Diabetes Survey</Title>
        </div>
        <div>
          <Button 
            size="md" variant="light" 
            onClick={logout}
            loading={loggingOut}
          >
            Logout
          </Button>
        </div>
      </div>
    </AppShell.Header>
  );
}