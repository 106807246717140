import * as React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { AppShell, Center, Loader } from '@mantine/core';

import Header from '../Header';
import styles from './index.module.css';
import { AppContext } from '../AppContext';

export default function AppContainer() {
	const navigate = useNavigate();
  const location = useLocation();

  const [ pendingCounts, setPendingCounts ] = React.useState(null);
  const { globalState, setGlobalState } = React.useContext(AppContext);

	function getPendingCounts() {
    fetch(process.env.REACT_APP_API_URL_BASE + '/api/getPendingCounts', {
      method: 'GET',
      credentials: 'include'
    }).then((response, error) => {
      if (response.status === 401) {
        navigate('/login', { state: { ref: location.pathname }});
        return;
      } else if (response.status !== 200) {
        console.log('SOMETHING WENT WRONG: ', response.status);
        return;
      } else {
        response.json().then((responseBody, error) => {
          if ( !globalState || !globalState.name ) {
            setGlobalState({
              ...globalState,
              name: responseBody.user.name,
              designation: responseBody.user.designation,
              userid: responseBody.user.userid
            });
          }

          setPendingCounts({
            fbs: parseInt(responseBody.count.fbs),
            ppbs: parseInt(responseBody.count.ppbs)
          });
        })
      }
    }).catch((error) => {
      console.log('SOMETHING WENT WRONG');
      console.log(error);
    })
  }

  // This is done to call useEffect only once, when the Component is mounted.
  const hasMounted = React.useRef(false);
  React.useEffect(() => {
    if (hasMounted.current) { return; }

    getPendingCounts();
    
    hasMounted.current = true;
  });

	return (
		<>
			{
				!pendingCounts ? 
					(<Center style={{ height: '100%' }}><Loader color="cyan" type="bars" /></Center>):
					(
						<div style={{ height: '100%' }}>
							<AppShell header={{ height: 60 }} padding="md">
								<Header />

								<AppShell.Main className={styles.mainContainer}>
									<div className="maxWidthContainer" style={{ display: "block" }}>
										<Outlet context={[ pendingCounts, setPendingCounts ]} />
									</div>
								</AppShell.Main>
							</AppShell>
						</div>
					)
			}
		</>
   );
}