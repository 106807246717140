import * as React from 'react';
import { Card, Button, NumberInput, PasswordInput, Flex, Space, Title, Stack, Center } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppContext } from '../../components/AppContext';

import styles from './index.module.css';

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();

  const { globalState, setGlobalState } = React.useContext(AppContext);
  const [loggingIn, setLoggingIn] = React.useState(false);

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      mobileNum: '',
      password: ''
    },
    validate: {
      mobileNum: (value) => (/^\d{10}$/.test(value) ? null : 'Invalid mobile number'),
    }
  });

  function handleSubmit(values, event) {
    setLoggingIn(true);
    localStorage.clear();

    fetch(process.env.REACT_APP_API_URL_BASE + '/api/auth', {
      method: 'POST',
      credentials: 'include',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        username: values.mobileNum,
        password: values.password
      })
    }).then((response, error) => {
      if (response.status !== 200) {
        form.setErrors({
          mobileNum: ' ',
          password: 'Invalid mobile number or password'
        });
        setLoggingIn(false);
      } else {
        
        response.json().then((responseBody) => {
          setGlobalState({
            ...globalState,
            name: responseBody.name,
            designation: responseBody.designation,
            userid: responseBody.id
          });

          const ref = location && location.state && location.state.ref;

          // Navigate to the page that redirected to login.
          return navigate((ref && ref !== '/') ? ref : '/home');
        });
      }
    }).catch((error) => {
      form.setErrors({

      });
    });
  }

  return (
    <Center style={{height: '100%'}}>

      <Card shadow="md" padding="lg" radius="md" withBorder>
        <Stack align="center" justify="center">
          <img src="/Telangana-LOGO.webp" className={styles.logo} alt="logo" />
          <Title order={3} className={styles.heading}>Diabetes Survey - Wanaparthy</Title>
        </Stack>

        <Space h="lg" />
        <Space h="lg" />
        <form onSubmit={form.onSubmit(handleSubmit)} className={styles.form}>
          <NumberInput
            withAsterisk
            label="Mobile Number"
            placeholder="Mobile Number"
            key={form.key('mobileNum')}
            {...form.getInputProps('mobileNum')}
            hideControls="true"
            size="lg"
            inputMode="numeric"
            allowNegative="false"
          />

          <Space h="md" />

          <PasswordInput
            withAsterisk
            label="Password"
            placeholder="Password"
            key={form.key('password')}
            {...form.getInputProps('password')}
            size="lg"
            inputMode="numeric"
          />

          <Space h="md" />

          <Flex justify="center" mt="md" mb="md" grow="true">
            <Button 
              fullWidth type="submit" size="lg" 
              variant="gradient" gradient={{ from: 'blue', to: 'violet', deg: 50 }}
              loading={loggingIn}
            >
              Login
            </Button>
          </Flex>
        </form>
      </Card>
    </Center>
  );
}