import * as React from 'react';
import { AppContext } from '../components/AppContext';
import { Button, Card, TextInput, NumberInput, SegmentedControl, MultiSelect, InputLabel,
  Flex, Space, Title, Text, Select, Badge, Divider} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Alert, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconInfoCircle } from '@tabler/icons-react';

export default function Login() {
  // Import global state.
  const { globalState } = React.useContext(AppContext);

  const constants = globalState.constants;

  // To control when the alert showing whether the reading is above threshold is visible.
  const [ alertVisible, alertHandlers ] = useDisclosure(false);

  // Modal shown when the entry is successful.
  const [ modalVisible, modalHandlers ] = useDisclosure(false);

  // To disable/enable the buttons/inputs while submitting the form, and to display error.
  const [ formState, setFormState ] = React.useState('reset');

  // To set error messages received from the server on submitting the form.
  const [ errorMessage, setErrorMessage ] = React.useState(null);

  // Used to set the type of test for which the entry is being done.
  const [ testType, setTestType ] = React.useState('rbs');

  // Used to set PHC selected to fill the SCs list accordingly.
  const [ phc, setPhc ] = React.useState(localStorage.getItem('phc'));

  // The list of SCs in the selected PHC.
  const [ scList, setScList ] = React.useState(null);

  // The selected SC.
  const [ sc, setSc ] = React.useState(localStorage.getItem('sc'));

  // The object that is passed to the PHC Select input.
  const phcInputData = Object.keys(constants.PHCSC).map((val) => { return { label: val, value: val }});

  // Used to store the address details of the testee, and update fields accordingly.
  const [ addressType, setAddressType ] = React.useState(localStorage.getItem('addressType') || 'rural');
  const [ mandal, setMandal ] = React.useState(localStorage.getItem('mandal'));
  const [ gpList, setGpList ] = React.useState(null);
  const [ gp, setGp ] = React.useState(localStorage.getItem('gp'));
  const [ municipality, setMunicipality ] = React.useState(localStorage.getItem('municipality'));
  const [ wardNo, setWardNo ] = React.useState(localStorage.getItem('wardNo'));

  // The object that is passed to the Mandal/Municipality Select Input.
  const mandalInputData = Object.keys(constants.MANDALGP).map((val) => { return { label: val, value: val }});
  const municipalityInputData = Object.keys(constants.MUNICIPALITIES).map((val) => { return { label: val, value: val }});

  /**
   * Sets the SC list state variable when PHC is updated.
   * 
   * @param  {String} phc The selected PHC
   * @return {void}     
   */
  function updateScList(phc) {
    if (! phc) return;

    setScList(constants.PHCSC[phc].map((val) => { return { label: val, value: val } }));
  }

  /**
   * Sets the GP list state variable when a Mandal is selected.
   * 
   * @param  {String} mandal The selected mandal.
   * @return {void}       
   */
  function updateGpList(mandal) {
    if (! mandal) return;

    setGpList(constants.MANDALGP[mandal].map((val) => { return { label: val, value: val } }));
  }

  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      name: '',
      fatherName: '',
      mobile: '',
      aadhaar: '',
      age: '',
      gender: 'M',
      rationCard: 'true',
      reading: '',
      comorbidities: [],
      test: 'rbs',
      phc: phc,
      sc: sc,
      mandal: mandal,
      gp: gp,
      municipality: municipality,
      wardNo: wardNo,
      addressType: addressType
    },

    validate: {
      name: (value) => (/^[A-Za-z. ]+$/.test(value) ? null : (value ? 'Invalid name' : 'Please enter name')),
      fatherName: (value) => (/^[A-Za-z. ]+$/.test(value) ? null : (value ? 'Invalid name' : 'Please enter name')),
      mobile: (value) => (/^\d{10}$/.test(value) ? null : (value ? 'Invalid mobile number' : 'Please enter mobile number')),
      aadhaar: (value) => (/^\d{12}$/.test(value) ? null : (value ? 'Invalid Aadhaar number' : 'Please enter Aadhaar number')),
      age: (value) => (/^\d+$/.test(value) 
        ? (value > 150 || value < 18 ? 'Age must be between 18 and 150' : null) 
        : (value ? 'Invalid age' : 'Please enter age')),
      reading: (value) => (/^[\d.]+$/.test(value) 
        ? (value < 60 ? 'Reading cannot be less than 60' : null) 
        : (value ? 'Invalid reading' : 'Please enter reading')),
      mandal: (value) => addressType === 'rural' ? (value ? null : 'Select mandal') : null,
      gp: (value) => addressType === 'rural' ? (value ? null : 'Select gram panchayat') : null,
      municipality: (value) => addressType === 'urban' ? (value ? null : 'Select municipality') : null,
      wardNo: (value) => addressType === 'urban' ? 
        (municipality ? 
          (value > 0 && value <= constants.MUNICIPALITIES[municipality] ? null : 'Enter valid ward number') : 
          'Select municipality') : 
        null,
      phc: (value) => value ? null : 'Select PHC',
      sc: (value) => value ? null : 'Select Sub Centre'
    }
  });

  // This is done to call useEffect only once, when the Component is mounted.
  const hasMounted = React.useRef(false);
  React.useEffect(() => {
    if (hasMounted.current) { return; }

    updateScList(phc);
    updateGpList(mandal);
    
    hasMounted.current = true;
  });

  /**
   * Called when the user submits the form.
   * 
   * @param  {Object} values Values of the form
   * @param  {Object} event  The event object
   * 
   * @return {void}        
   */
  function handleSubmit(values, event) {
    setFormState('submitting');

    fetch(process.env.REACT_APP_API_URL_BASE + '/api/enter', {
      method: 'POST',
      credentials: 'include',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        name: values.name,
        fatherName: values.fatherName,
        mobile: values.mobile,
        aadhaar: values.aadhaar,
        age: values.age,
        gender: values.gender,
        reading: parseFloat(values.reading),
        test: values.test,
        testAgain: alertVisible,
        referral: values.test !== 'rbs' && alertVisible,
        comorbidities: values.comorbidities,
        rationCard: values.rationCard === 'true',
        addressType: values.addressType,
        mandal: addressType === 'rural' ? values.mandal : null,
        gp: addressType === 'rural' ? values.gp : null,
        municipality: addressType === 'urban' ? values.municipality : null,
        wardNo: addressType === 'urban' ? values.wardNo : null,
        phc: values.phc,
        sc: values.sc
      })
    }).then((response, error) => {
      console.log(response);
      if (response.status !== 200) {
        response.json().then((responseBody, error) => {
          setErrorMessage(responseBody ? responseBody.error : null);
          setFormState('error');
        });
      } else {
        modalHandlers.open();
        setFormState('reset');
      }
    }).catch((error) => {
      setFormState('error');
      setErrorMessage(null);
      console.log(error);
    });
  }

  /**
   * Called when the reading value is changed. This is used to check if the value entered is 
   * more than the threshold.
   * 
   * @param  {Integer} value The reading value.
   * 
   * @return {void}       
   */
  function handleReadingValueChange(value) {
    if (value >= globalState.constants.THRESHOLDS[testType]) {
      alertHandlers.open();
    } else {
      alertHandlers.close();
    }
  }

  return (
    <div>
      <form onSubmit={form.onSubmit(handleSubmit)}>
      <Title order={3}>New RBS Entry</Title>
      <Space h="md" />
      <Card shadow="md" padding="lg" radius="md" withBorder style={{width: '100%'}}>
        <Flex justify="flex-end">
          <Badge color="pink">Surveyor Details</Badge>
        </Flex>

        <Select
          withAsterisk
          label="PHC"
          placeholder="Select"
          checkIconPosition="right"
          data={phcInputData}
          key={form.key('phc')}
          {...form.getInputProps('phc')}
          size="lg"
          disabled={formState === 'submitting'}
          value={phc}
          onChange={(value) => { 
            setPhc(value); 
            updateScList(value); 
            localStorage.setItem('phc', value); 
            form.setFieldValue('phc', value);
            form.setFieldValue('sc', null);
            localStorage.removeItem('sc');
          }}
          searchable
        />

        <Space h="md" />

        <Select
          withAsterisk
          label="Sub Centre"
          placeholder="Select"
          checkIconPosition="right"
          data={scList}
          key={form.key('sc')}
          {...form.getInputProps('sc')}
          size="lg"
          disabled={formState === 'submitting'}
          value={sc}
          onChange={(value) => { 
            localStorage.setItem('sc', value); 
            form.setFieldValue('sc', value); 
            setSc(value);
          }}
          searchable
        />
      </Card>
      
      <Space h="md" />

      <Card shadow="md" padding="lg" radius="md" withBorder style={{ width: '100%' }}>
        <Flex justify="flex-end">
          <Badge color="violet">Testee Details</Badge>
        </Flex>

        <TextInput
          withAsterisk
          label="Name"
          placeholder="Name"
          key={form.key('name')}
          {...form.getInputProps('name')}
          size="lg"
          disabled={formState === 'submitting'}
        />

        <Space h="md" />

        <TextInput
          withAsterisk
          label="Father's/Husband's Name"
          placeholder="Father's/Husband's Name"
          key={form.key('fatherName')}
          {...form.getInputProps('fatherName')}
          size="lg"
          disabled={formState === 'submitting'}
        />

        <Space h="md" />

        <Flex justify="center" align="center">
          <InputLabel size="lg" style={{ flexGrow: 4 }} required={true}>Gender</InputLabel>
          <SegmentedControl size="lg" color="blue" data={[
            { value: 'M', label: 'Male' },
            { value: 'F', label: 'Female' }]} 
            key={form.key('gender')}
            {...form.getInputProps('gender')}
            disabled={formState === 'submitting'}
          />
        </Flex>

        <Space h="md" />

        <NumberInput
          withAsterisk
          label="Age"
          placeholder="Age"
          key={form.key('age')}
          {...form.getInputProps('age')}
          hideControls="true"
          size="lg"
          inputMode="numeric"
          allowNegative="false"
          disabled={formState === 'submitting'}
        />

        <Space h="md" />

        <NumberInput
          withAsterisk
          label="Mobile Number"
          placeholder="Mobile Number"
          key={form.key('mobile')}
          {...form.getInputProps('mobile')}
          hideControls="true"
          size="lg"
          inputMode="numeric"
          disabled={formState === 'submitting'}
        />

        <Space h="md" />

        <Divider my="md" />

        {/* Address details of the testee. */}
        <Flex justify="center" align="center">
          <InputLabel size="lg" style={{ flexGrow: 4 }} required={true}>Address Type</InputLabel>
          <SegmentedControl size="lg" color="blue" data={[
            { value: 'rural', label: 'Rural' },
            { value: 'urban', label: 'Urban' }]} 
            key={form.key('addressType')}
            {...form.getInputProps('addressType')}
            disabled={formState === 'submitting'}
            onChange={(value) => { 
              setAddressType(value); 
              localStorage.setItem('addressType', value);
              form.setFieldValue('addressType', value); 
            }}
          />
        </Flex>

        <Space h="md" />
        {
          addressType === 'rural' ? (
            <>
              <Select
                withAsterisk
                label="Mandal"
                placeholder="Select"
                checkIconPosition="right"
                data={mandalInputData}
                key={form.key('mandal')}
                {...form.getInputProps('mandal')}
                size="lg"
                disabled={formState === 'submitting'}
                value={mandal}
                onChange={(value) => { 
                  setMandal(value); 
                  updateGpList(value); 
                  localStorage.setItem('mandal', value); 
                  form.setFieldValue('mandal', value);
                  form.setFieldValue('gp', null);
                  localStorage.removeItem('gp');
                }}
                searchable
              />

              <Space h="md" />

              <Select
                withAsterisk
                label="Gram Panchayat"
                placeholder="Select"
                checkIconPosition="right"
                data={gpList}
                key={form.key('gp')}
                {...form.getInputProps('gp')}
                size="lg"
                disabled={formState === 'submitting'}
                value={gp}
                onChange={(value) => { 
                  localStorage.setItem('gp', value); 
                  form.setFieldValue('gp', value); 
                  setGp(value);
                }}
                searchable
              />
            </>
          ) : (
            <>
              <Select
                withAsterisk
                label="Municipality"
                placeholder="Select"
                checkIconPosition="right"
                data={municipalityInputData}
                key={form.key('municipality')}
                {...form.getInputProps('municipality')}
                size="lg"
                disabled={formState === 'submitting'}
                value={municipality}
                onChange={(value) => { 
                  setMunicipality(value); 
                  localStorage.setItem('municipality', value); 
                  form.setFieldValue('municipality', value);
                }}
                searchable
              />

              <Space h="md" />

              <NumberInput
                withAsterisk
                label="Ward Number"
                placeholder="Ward Number"
                key={form.key('wardNo')}
                {...form.getInputProps('wardNo')}
                hideControls="true"
                size="lg"
                inputMode="numeric"
                allowNegative="false"
                disabled={formState === 'submitting'}
                value={wardNo}
                onBlur={(event) => { 
                  setWardNo(event.target.value);
                  localStorage.setItem('wardNo', event.target.value);
                  form.setFieldValue('wardNo', event.target.value);
                }}
              />
            </>
          )
        }

        <Divider my="md" />

        <Space h="md" />

        <NumberInput
          withAsterisk
          label="Aadhaar Number"
          placeholder="Aadhaar Number"
          key={form.key('aadhaar')}
          {...form.getInputProps('aadhaar')}
          hideControls="true"
          size="lg"
          inputMode="numeric"
          allowNegative="false"
          disabled={formState === 'submitting'}
        />

        <Space h="md" />

        <Flex justify="center" align="center">
          <InputLabel size="lg" style={{ flexGrow: 4 }} required={true}>Ration Card</InputLabel>
            <SegmentedControl size="lg" color="blue" data={[
              { value: 'true', label: 'Yes' },
              { value: 'false', label: 'No' }]} 
              key={form.key('rationCard')}
              {...form.getInputProps('rationCard')}
              disabled={formState === 'submitting'}
            />
        </Flex>

        <Space h="md" />

        <MultiSelect
          label="Comorbidities/Habits"
          checkIconPosition="right"
          key={form.key('comorbidities')}
          {...form.getInputProps('comorbidities')}
          data={[
            { value: 'hypertension', label: 'Hypertension' },
            { value: 'heart_disease', label: 'Heart Disease' },
            { value: 'kidney_disease', label: 'Kidney Disease' },
            { value: 'liquor', label: 'Alcohol Consumption'},
            { value: 'smoke', label: 'Smoking' }
          ]}
          size="lg"
          comboboxProps={{ shadow: 'md' }}
          styles={{
            pill: { backgroundColor: 'var(--mantine-color-blue-1)' }
          }}
          disabled={formState === 'submitting'}
        />

        <Space h="md" />

        <Select
          withAsterisk
          label="Test"
          placeholder="Select"
          checkIconPosition="right"
          data={[
            { value: 'rbs', label: 'RBS' },
            { value: 'fbs', label: 'FBS' },
            { value: 'ppbs', label: 'PPBS' }
          ]}
          key={form.key('test')}
          {...form.getInputProps('test')}
          size="lg"
          disabled={true}
          onChange={(value) => { setTestType(value); form.setFieldValue('test', value); }}
        />

        <Space h="md" />


        <NumberInput
          withAsterisk
          label="Test Reading"
          placeholder="Test Reading"
          key={form.key('reading')}
          {...form.getInputProps('reading')}
          hideControls="true"
          size="lg"
          inputMode="numeric"
          allowDecimal="true"
          allowNegative="false"
          onChange={handleReadingValueChange}
          onBlur={(event) => { form.setFieldValue('reading', event.target.value) }}
          disabled={formState === 'submitting'}
        />

        {
          alertVisible ? (
            <div>
              <Space h="md" />
              <Alert variant="light" color="red" title={'High ' + testType.toUpperCase() } 
                icon={<IconInfoCircle />} >
                {
                  testType === 'rbs' ? (
                    <>
                      Since the RBS reading is more than {globalState.constants.THRESHOLDS[testType]}, 
                      more readings are needed to ascertain whether the person is diabetic. 
                      The tests will have to be done again <b>tomorrow</b>, once on empty stomach and 
                      once after fasting for a minimum of two hours.
                    </>
                  ) : (
                    <>
                      Since the {testType.toUpperCase()} reading is more 
                      than {globalState.constants.THRESHOLDS[testType]}, <b>please refer the person to the nearest PHC</b>.
                    </>
                  )

                }
                
              </Alert>
            </div>
          ) : ""
        }

        <Space h="md" />

        <Flex justify="center" mt="md" mb="md" grow="true">
          <Button 
            type="submit" size="lg"
            variant="gradient" gradient={{ from: 'green', to: 'cyan', deg: 90 }}
            loading={formState === 'submitting'}
          >
            Submit Data
          </Button>
        </Flex>
      </Card>

      <Modal 
        opened={modalVisible} 
        onClose={modalHandlers.close} 
        centered
        overlayProps={{
          backgroundOpacity: 0.5,
          blur: 3
        }}
        withCloseButton={false}
      >
        <Text size="lg">Data entered successfully.</Text>
        <Space h="xl" />

        <Flex justify="flex-end">
          <Button
            onClick={() => {
              modalHandlers.close();
              form.reset();
              alertHandlers.close();
              window.scrollTo(0, 0);
            }}
            variant="gradient" gradient={{ from: 'blue', to: 'violet', deg: 50 }}
            >
              Dismiss
          </Button>
        </Flex>
      </Modal>

      <Modal 
        opened={formState === 'error'} 
        onClose={() => setFormState(null)} 
        centered
        overlayProps={{
          backgroundOpacity: 0.5,
          blur: 3
        }}
        withCloseButton={false}
      >
        <Text size="lg" c="red">ERROR: { errorMessage ? errorMessage : "Something went wrong." } </Text>
        
        <Space h="xl" />

        <Flex justify="flex-end">
          <Button
            onClick={() => setFormState(null)}
            variant="gradient" gradient={{ from: 'blue', to: 'violet', deg: 50 }}
            >
              Dismiss
          </Button>
        </Flex>
      </Modal>
      </form>
    </div>
  );
}