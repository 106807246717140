const constants = {
	THRESHOLDS: {
		rbs: 125,
		fbs: 125,
		ppbs: 160
	},
	PHCSC: {
		'Apparala':	['Apparala', 'Miraspally', 'Ramakistapur', 'Ranipeta'],
		'Ghanpur': ['Ghanpur-A', 'Ghanpur-B', 'Manajipet', 'Solipur'],
		'Gopalpet': ['Buddaram', 'Chennaram', 'Chennur', 'Edula', 'Edutla', 
			'Gopalpet', 'Jainthirmalapur', 'Keshampet', 'Poikipad', 'Raipakula', 
			'Revally', 'Nagapoor', 'Tadiparthy', 'Talpanoor'],
		'Kadukuntla': ['Appaipally', 'Chandapur', 'Chinnaguntapally', 'HWC Nagavaram', 
			'Kadukuntla', 'Peddagudem', 'Rajanagaram', 'Chityala(K)'],
		'Kamaluddinpur': ['Agaram', 'Kamaluddinpur', 'Mamidimada'],
		'Kothakota': ['Amadabakula', 'Kanimetta', 'Kothakota-A', 'Kothakota-B', 'Kothakota-C', 'Palem'],
		'Madnapur': ['Ajjakole', 'Duppally', 'Gopanpet', 'Madanapur-A', 'Madanapur-B', 'Konnoor'],
		'Pangal': ['Busireddipally', 'Mahamadapur', 'Tellarallapally', 'Vengalaipally', 'Annaram', 
			'Chintakunta', 'Pangal', 'Kethepally', 'Remaddula', 'Shagapur'],
		'Pebbair': ['Kanchiraopally', 'Kistareddipet', 'Pebbair-A', 'Pebbair-B', 'Rangapur',
			'Shagapur(Y)', 'Sugur'],
		'Peddamandadi': ['Balijepally', 'Chinnamandadi', 'Dodiguntapally', 'Manigilla', 'Pamireddypally',
			'Peddamandadi', 'Veltoor(P)', 'Maddigatla'],
		'Srirangapur': ['Ayyawaripally', 'Srirangapur', 'Kambhalapur', 'Gummadam', 'Yaparala', 'Venkatapur'],
		'Thippadampally': ['Amarachintha-B', 'Atmakur-A', 'Atmakur-B', 'Motlonipally', 'Mustipoor', 
			'Nagalkudmur', 'Nandimalla', 'Rechinthala', 'Thipadampally'],
		'Veepanagandla': ['Bekkem', 'Bollaram', 'Koppunur', 'Peddadagada', 'Peddamaroor', 
			'Kalwarala', 'Chellepad', 'Pulgarcherla', 'Thoomkunta', 'Veepanagandla', 'Velagonda', 'Veltoor'],
		'PPU Wanaparthy': ['PPU Wanaparthy'],
		'UPHC Gandhinagar': ['UPHC Gandhinagar'],
		'UPHC Peerlagutta': ['UPHC Peerlagutta']
	},
	MANDALGP: {
		'Amarchintha':	['Chandragad', 'Chandranaik Thanda',  'Chinthareddypally',  'Dharmapur',  'Erladinne',  
			'Kankanvanipalle',  'Kistampally',  'Mastipur',  'Mittanandimalla',  'Nagalkadumur',  'Nandimalla',  
			'Nandimalla X Roads',  'Pamireddipalle',  'Singampeta'],
		'Athmakur': ['Arepalle', 'Balakistapur', 'Devarapalle', 'Guntipalle', 'Jurial', 'Kathepalle', 'Medepalle', 
			'Motlampalle', 'Mulmalla', 'Pinamcherla', 'Rechintala', 'Thippadampalle', 'Thoompalle', 'Veeraraghavapur'],
		'Chinnambavi': ['Ammaipally', 'Ayyawari Pally', 'Bekkem', 'Chellepad', 'Chinnamaroor', 'Chinnambavi', 
			'Dagadapally', 'Gaddabaswapur', 'Gudem', 'Kaloor', 'Koppunoor', 'Laxmipally', 'Miyapoor', 'Pedda Maroor', 
			'Peddadagada', 'Velgonda', 'Veltoor'],
		'Ghanpur': ['Agaram', 'Almaipally', 'Amudam Banda Thanda', 'Anthaipally', 'Appareddipally', 'Donthikunta Thanda', 
			'Eerla Thanda', 'Gattukadipally', 'Ghanpur', 'Kamaluddinpur', 'Karne Thanda', 'Kothula Kunta Thanda', 
			'Malkamiyanpally', 'Malkapur', 'Mamidimada', 'Manajipet', 'Mudnari Thanda', 'Parvathapur', 
			'Road Meedi Thanda', 'Rukkannapally', 'Salkalapur', 'Shapur', 'Solipur', 'Suraipally', 
			'Thirumalaipally', 'Upperpally', 'Vasuram Thanda', 'Veniki Thanda', 'Venkatampally'],
		'Gopalpet': ['Amudalakunta Thanda', 'Ananthapur', 'Buddaram', 'Chakalapally', 'Chennur', 'Gopalpet', 
			'Jainthirmalapur', 'Jinkalabeedu Thanda', 'Karnamaiah Kunta Thanda', 'Laxmi Thanda', 'Laxmidevi Pally', 
			'Patigadda Thanda', 'Polkepad', 'Tadiparthy', 'Yedula', 'Yedutla', 'Munnanoor'],
		'Kothakota': ['Amdabakula', 'Apparala', 'Bhoothpur', 'Cherlapally', 'Kanaipally', 'Kanimetta', 
			'Mirasipally', 'Mummallapally', 'Nacharampeta', 'Natavelli', 'Natavelly Pedda Thanda', 'Nirven', 
			'Palem', 'Pamapur', 'Pathajangamai Pally', 'Ramakrishnapur', 'Ramanthapur', 'Rayanpet', 'Sankireddipally', 
			'Satyahally', 'Waddewata', 'Waddewat Thanda', 'Wiliam Konda', 'Yedulabai Thanda'],
		'Madanapur': ['Ajjakole', 'Bhavsing Thanda', 'Danthanur', 'Duppalle', 'Dwarakanagar', 'Gopanpet', 
			'Govindahally', 'Karvena', 'Konnur', 'Konnur Pedda Thanda', 'Kothapally', 'Madanapur', 'Narsingapur', 
			'Nelividi', 'Ramanpahad', 'Shankarampeta', 'Thirumalaipally'],
		'Pangal': ['Annaram', 'Annaram Thanda', 'Bahadur Gudem', 'Bandapally', 'Busireddypally', 'Chikkepally', 
			'Chinthakunta', 'Davajipally', 'Dawoodkhanpally', 'Dondaipally', 'Goplapur', 'Jammapoor', 'Kadirepad', 
			'Kethepally', 'Kistapur Thanda', 'Kothapet', 'Madhavaraopally', 'Mallaipally', 'Mohammadapur', 'Nizamabad',
			'Pangal', 'Rayanpally', 'Remuddula', 'Shakapoor', 'Shakhapur Thanda', 'Tellarallapally', 
			'Tellarallapally Thanda', 'Vengalaipally'],
		'Pebbair': ['Ayyavaripally', 'Buniyadpur', 'Burdipad', 'Erladinne', 'Gummadam', 'Gummadam Thanda', 
			'Janumpally', 'Kanchiraopally', 'Kanchiraopally Thanda', 'Kotha Sugur', 'Pathapally', 'Penchikalpad', 
			'Ramammapeta', 'Rampur', 'Rangapoor', 'Shakapoor (Y)', 'Sugur', 'Thippaipally', 'Thomalapally', 'Yaparla'],
		'Peddamandadi': ['Alwal', 'Ammapally', 'Anakaipally Thanda', 'Balijapally', 'Buggapally Thanda', 
			'Cheekuru Chettu Thanda', 'Chilkatonipally', 'Chinnamandadi', 'Dodaguntapally', 'Gatlakhanapur', 
			'Jagathpally', 'Jangamaipally', 'Maddigatla', 'Mangampally', 'Manigilla', 'Mojerla', 'Mundari Thanda', 
			'Nagulakunta Thanda', 'Pamireddipally', 'Peddamandadi', 'School Thanda', 'Veeraipally', 'Veltoor'],
		'Revally': ['Bandaraipakula', 'Cheerkapally', 'Chennaram', 'Gollapally', 'Gouridevipally', 'Keshampet', 
			'Konkalapally', 'Nagapur', 'Patha Thanda', 'Revally', 'Shanaipally', 'Thalpunoor'],
		'Srirangapur': ['Janampet', 'Kambalapur', 'Nagarala', 'Nagasanipally', 'Seripally', 'Srirangapur', 'Thatipamula', 
			'Venkatapur'],
		'Veepangandla': ['Bollaram', 'Gopaldinne', 'Govardhanagiri', 'Kalwarala', 'Korlakunta', 
			'Nagarlabanda Thanda', 'Pulgercherla', 'Rangavaram', 'Sampathraopally', 'Sanginenipally', 
			'Thoomukunta', 'Vallabhapur', 'Vallabhapur Thanda', 'Weepangandla'],
		'Wanaparthy': ['Achuthapur', 'Anjanagiri', 'Ankoor', 'Appaipally', 'Chandapur', 'Chimanguntapally', 
			'Chityala', 'Dattaipally', 'Guntha Thanda', 'Kadukuntla', 'Kandriya Thanda', 'Keerya Thanda', 'Khasimnagar', 
			'Kistagiri', 'Mentapally', 'Nachahally', 'Nagamma Thanda', 'Padamati Thanda', 'Pedda Thanda D', 'Pedda Thanda R', 
			'Peddagudem', 'Peddagudem Thanda', 'Rajapet', 'Savaigudem', 'Thurupu Thanda', 'Venkatapur']
	},
	MUNICIPALITIES: {
		'Amarchintha': 10,
		'Athmakur': 10,
		'Kothakota': 15,
		'Pebbair': 15,
		'Wanaparthy': 33
	}
};

export default constants;